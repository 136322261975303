import React from 'react';
import classNames from 'classnames/bind';
import classes from './TreatmentContract.module.scss';

const cx = classNames.bind(classes);

type TreatmentContractProps = {
  doctorStamp: string;
};

export function TreatmentContract(props: TreatmentContractProps) {
  let patient: any = {};

  const patientInfo = sessionStorage.getItem('patient');
  if (patientInfo === null) {
    // eslint-disable-next-line no-alert
    alert('Error - no patientInfo in sessionStorage');
  }
  if (patientInfo !== null) {
    patient = JSON.parse(patientInfo);
  }
  const userBirthday = new Date(patient.birthday).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const addressSet = patient?.address && patient.address.length > 0;

  const firstAddress = addressSet ? patient.address[0] : null;

  let patientStreet = '';
  let patientHouseNumber = '';
  let patientPostcode = '';
  let patientCity = '';

  if (firstAddress) {
    patientStreet = firstAddress.street;
    patientHouseNumber = firstAddress.houseNumber;
    patientPostcode = firstAddress.postcode;
    patientCity = firstAddress.city;
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <h1>Behandlungsvertrag</h1>
        <div>
          <strong>Zwischen</strong>
        </div>
        <p>
          {patient.firstname} {patient.lastname}
        </p>
        <p>geb. am {userBirthday}</p>
        <div>
          <strong>wohnhaft in</strong>
        </div>
        <p>
          {patientStreet} {patientHouseNumber}
        </p>
        <p>
          {patientPostcode} {patientCity}
        </p>
        <div>
          <strong>und</strong>
        </div>
        <div className={cx('DoctorStamp')}>{props.doctorStamp}</div>
      </React.Fragment>
      <React.Fragment>
        <h2>über die Erbringung medizinischer Leistungen im Rahmen eines Behandlungsvertrages (§ 630a BGB)</h2>
        <p>
          <strong></strong>
        </p>
        <h3>§ 1 Vertragsgegenstand</h3>
        <p>
          <strong></strong>
        </p>
        <p>
          1.1 Diese Regelungen gelten, soweit nichts anderes vereinbart ist, für die vertraglichen Beziehungen zwischen
          Arzt und Patient.
        </p>
        <p>
          1.2 Der Arzt erbringt die medizinische Behandlung nach den Regeln der ärztlichen Kunst. Der Arzt schuldet dem
          Patienten keinen Behandlungserfolg. Dabei werden mitunter auch Methoden angewendet, die schulmedizinisch nicht
          anerkannt sind und nicht dem Stand der Wissenschaft entsprechen. Diese Methoden können auch nicht
          kausal-funktional erklärbar und insofern nicht zielgerichtet sein. Insofern kann ein subjektiv erwarteter
          Erfolg der Methode teilweise weder in Aussicht gestellt noch garantiert werden.
        </p>
        <p>
          1.3 Sollte der Behandlungsvertrag mit einer anderen Person (gesetzlicher oder bevollmächtigter Vertreter) als
          der Patientin abgeschlossen werden, gelten die Regelungen dieses Behandlungsvertrages entsprechend.
        </p>
        <h3>§ 2 Rechtsverhältnis</h3>
        <p>2.1 Die Rechtsbeziehungen zwischen Arzt und Patient sind privatrechtlicher Natur.</p>
        <p>
          2.2 Der Behandlungsvertrag kommt unmittelbar zwischen dem Patienten und dem behandelnden Arzt zustande. Die
          nowomed GmbH ist nicht Vertragspartei des Behandlungsvertrages.
        </p>
        <h3>§ 3 Ärztliche Vergütung für Privatversicherte</h3>
        <p>
          3.1 Das Entgelt für die Leistungen des Arztes für privatärztliche Leistungen bestimmt sich nach den
          Vorschriften der Amtlichen Gebührenordnung für Ärzte (GOÄ) in der jeweils gültigen Fassung. Eine Erstattung
          durch den Kostenträger ist nicht gewährleistet.
        </p>
        <p>3.2 Mit Zugang der Rechnung wird die Zahlung fällig.</p>
        <p>3.3 Die Patientin kommt nach einer Mahnung, spätestens aber 30 Tage nach Rechnungszugang, in Verzug.</p>
        <p>
          <strong>§ 4 Forderungsabtretung, Datenweitergabe und Schweigepflichtentbindung</strong>
        </p>
        <p>
          4.1 Die Zahlungsabwicklung der ärztlichen Vergütung erfolgt vollständig über den Kooperationspartner des
          Arztes, die nowomed GmbH, Zum Handelshof 3, 39108 Magdeburg und der von dieser beauftragten Ärztliche
          Verrechnungsstelle Büdingen GmbH, Gymnasiumstraße 18–20, 63654 Büdingen.
        </p>
        <p>
          4.2 Der Patient stimmt ausdrücklich zu, dass der Arzt die sich aus der Behandlung ergebende Forderung an die
          nowomed GmbH abtritt und diese die Forderung an die Ärztliche Verrechnungsstelle Büdingen GmbH weiterabtritt.
          Der Patient ist sich bewusst, dass nach erfolgter Weiterabtretung ihm gegenüber die Ärztliche
          Verrechnungsstelle Büdingen GmbH als Forderungsinhaberin auftritt und deshalb Einwände gegen die Forderung –
          auch soweit sie sich aus der Behandlung und der Krankengeschichte ergeben – im Streitfall gegenüber der
          Ärztliche Verrechnungsstelle Büdingen GmbH zu erheben und geltend zu machen sind und der Arzt als Zeuge
          vernommen werden kann. Zahlungen, die der Patient nach der Forderungsabtretung unmittelbar an den Arzt
          leistet, entfalten keine befreiende Wirkung und die Verpflichtung zur Zahlung gegenüber der Ärztliche
          Verrechnungsstelle Büdingen GmbH besteht weiterhin
        </p>
        <p>
          4.3 Der Patient wurde davon in Kenntnis gesetzt und erklärt sich hiermit gemäß Art. 6 Abs. 1 S. 1. lit. a
          DS-GVO einverstanden, dass die im Rahmen dieses Vertrages erhobenen Daten über seine Person, insbesondere
          Gesundheitsdaten gemäß Art. 9 Abs. 1 DSGVO i.V.m. Art. 4 Nr. Nr. 15 DSGVO, im erforderlichen Maße gespeichert,
          verarbeitet und genutzt werden und im Rahmen der Zweckbestimmung unter Beachtung der jeweiligen
          datenschutzrechtlichen Regelungen an Dritte (z.B. mitbehandelnde, weiterbehandelnde und/oder in Vertretung
          behandelnde Ärzte sowie z.B. an die nowomed GmbH bzw. die Ärztliche Verrechnungsstelle Büdingen GmbH im Rahmen
          der Forderungsabrechnung) übermittelt werden können.
        </p>
        <p>
          4.4 Der Patient entbindet den Arzt und die dort angestellten Mitarbeiter zu obigen beschriebenen Zwecken
          ausdrücklich von der ärztlichen Schweigepflicht.
        </p>
        <p>
          4.5 Diese Einverständniserklärung und Schweigepflichtentbindung gilt auch für zukünftige Behandlungen und
          Honorarforderungen. Sie kann ganz oder teilweise jederzeit ohne Angabe von Gründen widerrufen werden. Das kann
          unter anderem schriftlich geschehen an die oben aufgeführte Anschrift des Arztes. Der Patient hat weiterhin
          verstanden, dass durch den Widerruf der Einwilligung die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
          Widerruf erfolgten Verarbeitung nicht berührt wird.
        </p>
        <p>
          <strong>§ 5 Ausfallhonorar, Stornierung und Umbuchung</strong>
        </p>
        <p>
          5.1 Einen von Ihnen gebuchten Termin können Sie bis zu 24 Stunden vor dem geplanten Termin kostenfrei
          stornieren und umbuchen. Dazu können Sie das innerhalb der nowomed Web-Plattform bzw. der nowomed-App
          angelegte Terminbuchungssystem nutzen.
        </p>
        <p>
          5.2 Stornieren Sie den Termin innerhalb von 24 Stunden vor dem geplanten Termin, erhebt nowohealth ein
          Ausfallhonorar in Höhe von 59,- Euro bei Erstgesprächen und Folgevideosprechstunden bis 30 Min. Für
          Folgevideosprechstunden bis 10 Min. wird ein Ausfallhonorar von 39,- Euro fällig.
        </p>
        <p>
          5.3 Das erhöhte Ausfallhonorar von 80,- wird von nowohealth ebenso erhoben, wenn Sie einen Termin nicht
          stornieren aber zu dem vereinbarten Termin nicht erscheinen bzw. nicht erreichbar sind.
        </p>
        <p>
          5.4 Nowohealth erlässt Ihnen das Ausfallhonorar nur, wenn Sie mittels ärztlichem oder anderweitigem
          behördlichem Zeugnis gegenüber nowohealth nachweisen, dass ihnen die Wahrnehmung des Termins nicht möglich
          war.
        </p>
        <h3>§ 6 Einstellung der Behandlung</h3>
        <p>
          6.1 Kommt der Behandelnde vor oder im Rahmen der Behandlung zu dem Schluss, dass der Patient aus
          medizinisch-therapeutischer Sicht für die avisierte Therapie (insb. der cannabisbasierten Schmerztherapie)
          nicht geeignet ist und insoweit ein medizinisch-therapeutischer Erfolgt unwahrscheinlich ist, kann die
          Behandlung jederzeit eingestellt beziehungsweise gar nicht erst begonnen werden.
        </p>
        <p>
          6.2 Dem Patienten erwachsen aus einer solchen Behandlungseinstellung beziehungsweise dem Nicht-Beginnen der
          Behandlung keinerlei Schadensersatzansprüche, sofern der Abbruch nicht zur Unzeit erfolgt und dem Patienten
          allein daraus ein Schaden erwächst.
        </p>
        <h3>§ 7 Ärztliche Vergütung für gesetzlich Versicherte</h3>
        <p>
          Sollte kein gesetzlicher Krankenversicherungsschutz bestehen oder sollten Wahlleistungen (nach gesondertem
          Vertragsabschluss) in Anspruch genommen werden, die vom gesetzlichen Krankenversicherungsschutz nicht
          abgedeckt sind, ist der Patient gegenüber dem Arzt Selbstzahler. In diesen Fällen gelten die Bestimmungen
          unter § 3 entsprechend.
        </p>
        <h3>§ 8 Abtretungsverbot</h3>
        <p>
          Die Abtretung von nicht rechtskräftig festgestellten oder unbestrittenen Forderungen aus dem Behandlungs-
          verhältnis ist ausgeschlossen, soweit der Arzt dieser nicht vorher zustimmt.
        </p>
      </React.Fragment>
    </React.Fragment>
  );
}
