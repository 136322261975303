import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, PickerField } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { BasicHeader } from '../../../organisms/Header/BasicHeader';
import { countryOptions, genderOptions } from './PickerFieldOptions';
import style from './Step7.module.scss';

const cx = classNames.bind(style);
export function Step7(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [street, setStreet] = useState('');
  const [housenumber, setHouseNumber] = useState('');
  const [zip, setZip] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  useEffect(() => {
    const step6 = sessionStorage.getItem('questionnaire-step6');
    if (!step6) {
      navigation('/questionnaire/step-6', { replace: true });
    }
    const personalData = sessionStorage.getItem('patient');
    if (personalData !== null) {
      const parsedData = JSON.parse(personalData);
      setFirstname(parsedData.firstname);
      setLastname(parsedData.lastname);
      setEmail(parsedData.email);
      setBirthday(parsedData?.birthday);
      setMobile(parsedData.mobile);
      setGender(parsedData.sex);
    }
    setLoading(false);
    const addressData = sessionStorage.getItem('questionnaire-addressData');
    if (addressData !== null) {
      const parsedAddressData = JSON.parse(addressData);
      setStreet(parsedAddressData.street);
      setHouseNumber(parsedAddressData.houseNumber);
      setZip(parsedAddressData.postcode);
      setLocation(parsedAddressData.city);
      setCountry(parsedAddressData.country);
    }
  }, []);

  const isButtonDisabled =
    firstname === '' ||
    lastname === '' ||
    gender === undefined ||
    gender === '' ||
    birthday === '' ||
    street === '' ||
    housenumber === '' ||
    zip === '' ||
    location === '' ||
    country === '' ||
    mobile === '';

  const setPersonalData = () => {
    const personalData = {
      firstname,
      lastname,
      sex: gender,
      birthday,
      email,
      mobile,
    };
    const addressData = {
      street,
      houseNumber: housenumber,
      postcode: zip,
      city: location,
      country,
    };

    sessionStorage.setItem('questionnaire-personalData', JSON.stringify(personalData));
    sessionStorage.setItem('questionnaire-addressData', JSON.stringify(addressData));

    navigation('/questionnaire/step-8', { replace: true });
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const dividerContainerClasses = cx('flex', 'flex-center', 'mb-3', 'mt-3');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <BasicHeader />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={7} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Persönliche Daten</Headline>
        </div>
        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div>
                <Label>Vorname</Label>
              </div>
              <div>
                <InputField value={firstname} onChange={setFirstname} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Nachname</Label>
              </div>
              <div>
                <InputField value={lastname} onChange={setLastname} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Geschlecht</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={genderOptions}
                  value={gender}
                  onChange={setGender}
                />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Geburtstag</Label>
              </div>
              <div>
                <InputField type={'Date'} onChange={setBirthday} value={birthday} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>E-Mail</Label>
              </div>
              <div>
                <InputField type='email' value={email} onChange={setEmail} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Telefonnummer</Label>
              </div>
              <div>
                <InputField type='tel' value={mobile} onChange={setMobile} />
              </div>
            </div>
            <div className={dividerContainerClasses}></div>
            <Headline level={3}>Ihre Anschrift</Headline>
            <div className={questionContainerClasses}>
              <div>
                <Label>Straße</Label>
              </div>
              <div>
                <InputField value={street} onChange={setStreet} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Hausnummer</Label>
              </div>
              <div>
                <InputField value={housenumber} onChange={setHouseNumber} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>PLZ</Label>
              </div>
              <div>
                <InputField type='number' isNumber={true} value={zip} onChange={setZip} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Ort</Label>
              </div>
              <div>
                <InputField value={location} onChange={setLocation} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Land</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={countryOptions}
                  value={country}
                  onChange={setCountry}
                />
              </div>
            </div>
            <div className={buttonContainerClasses}>
              <Button disabled={isButtonDisabled} onClick={setPersonalData}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
